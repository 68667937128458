.modal-call {
  @extend %cover;

  background-color: black;
  position: fixed;
  opacity: 0;
  pointer-events: none;
  transition-property: opacity;
  transition-duration: time(default);
  transition-timing-function: ease(inout);
  z-index: 80;

  @media (max-width: 1023px) {
    overflow: auto;
  }

  &.open {
    opacity: 1;
    pointer-events: initial;
  }

  .background {
    background-color: black;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: calc(50% - 50px);
    z-index: 1;

    @media (max-width: 1023px) {
      height: 380px;
    }

    img {
      @extend %cover;

      opacity: 0.3;
    }
  }

  .container {
    position: relative;
    z-index: 2;
  }

  .logo {
    position: absolute;
    top: 40px;
    left: 25px;
    z-index: 3;
  }

  .close {
    position: absolute;
    top: 40px;
    right: 25px;
    z-index: 5;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;

    @media (max-width: 1023px) {
      height: auto;
      min-height: 100vh;
      padding: 135px 0 60px;
    }

    &-header {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      width: 765px;

      @media (max-width: 1023px) {
        flex-direction: column;
        width: 100%;
      }

      &--title {
        color: white;
        font-family: font(title);
        font-size: 56px;
        font-weight: 300;
        width: 285px;

        @media (max-width: 1023px) {
          font-size: 48px;
        }
        @media (max-width: 355px) {
          font-size: 40px;
        }
      }

      &--description {
        width: 275px;
        padding-top: 15px;

        @media (max-width: 1023px) {
          width: 100%;
          padding: 0;
          margin-top: 24px;
        }

        p {
          color: #93b1d3;
          font-size: 14px;
          font-weight: normal;
          line-height: 1.8;

          @media (max-width: 1023px) {
            color: white;
            font-size: 16px;
            font-weight: normal;
          }
        }
      }
    }

    &-form {
      width: 765px;
      margin-top: 120px;

      @media (max-width: 1023px) {
        width: 100%;
      }

      .form-flex {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 24px;
        grid-row-gap: 32px;

        @media (max-width: 1023px) {
          grid-template-columns: repeat(1, 1fr);
        }

        &:not(:last-child) {
          margin-bottom: 32px;
        }

        .field-group {
          position: relative;
          width: 100%;

          .label {
            color: white;
            font-size: 10px;
            font-weight: bold;
            letter-spacing: 0.08em;
            text-transform: uppercase;
            margin-bottom: 10px;
          }

          svg {
            position: absolute;
            bottom: 14px;
            left: 16px;
            pointer-events: none;
            z-index: 1;
          }

          .field {
            background-color: #0f0f0f;
            color: #93b1d3;
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 0.02em;
            width: 100%;
            display: block;
            height: 40px;
            padding: 0 16px;
            border: none;
            border-bottom: 1px solid #93b1d3;
            transition-property: background-color;
            transition-duration: time(default);
            transition-timing-function: ease(inout);

            &::placeholder {
              color: #93b1d3;
            }
          }

          &.icon .field {
            padding-left: 50px;
          }

          &.invalid .field {
            background-color: fade-out(red, 0.8);
          }
        }
      }

      .form-submit {
        width: 370px;
        margin: 48px auto 0;

        @media (max-width: 1023px) {
          max-width: 100%;
        }

        button {
          @extend %gradient-button;

          width: 100%;
          height: 48px;
          border-radius: 50px;
          overflow: hidden;

          span {
            color: black;
            font-size: 10px;
            font-weight: bold;
            letter-spacing: 0.08em;
            text-transform: uppercase;
          }
        }
      }
    }
  }
}
