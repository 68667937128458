#main-footer {
  background-color: black;

  .container {
    position: relative;

    > * {
      position: relative;
      z-index: 2;
    }
  }

  .background {
    background: linear-gradient(
      244.09deg,
      #001834 0%,
      fade-out(#001834, 1) 36%
    );
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: calc(100vw - ((100vw - 1170px) / 2));
    z-index: 1;

    @media (max-width: 1023px) {
      display: none;
    }
  }

  .headline {
    padding-top: 110px;
    width: calc(100% - 100px);
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 1023px) {
      display: none;
    }

    &-title {
      color: #93b1d3;
      font-size: 80px;
      font-weight: 300;
      font-family: font(title);
      letter-spacing: -0.02em;
      opacity: 0.2;
    }

    &-actions {
      display: flex;
      align-items: center;

      .action {
        &:not(:last-child) {
          margin-right: 45px;
        }

        a {
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          height: 48px;
          padding: 1px 16px 0;
          border: 1px solid #afaab4;
          border-radius: 24px;
          transition-property: border-color;
          transition-duration: time(default);
          transition-timing-function: ease(inout);

          &::before {
            @extend %gradient-button;

            content: '';
            position: absolute;
            top: -1px;
            right: -1px;
            bottom: -1px;
            left: -1px;
            border-radius: 50px;
            overflow: hidden;
            opacity: 0;
            transition-property: opacity;
            transition-duration: time(default);
            transition-timing-function: ease(inout);
            z-index: 1;
          }

          span {
            margin-right: 15px;
            color: white;
            font-size: 10px;
            font-weight: 700;
            text-transform: uppercase;
            letter-spacing: 0.08em;
            position: relative;
            z-index: 2;
          }

          svg {
            position: relative;
            z-index: 2;
          }

          &:hover {
            border-color: transparent;

            &::before {
              opacity: 1;
            }
          }
        }
      }
    }
  }

  .flex-wrapper {
    width: calc(100% - 100px);
    border-top: 1px solid fade-out(#93b1d3, 0.8);
    margin-top: 70px;
    padding-top: 70px;
    display: flex;
    justify-content: space-between;

    @media (max-width: 1023px) {
      flex-direction: column;
      align-items: center;
      width: 100%;
      margin: 0;
      padding: 100px 0 0;
      border: none;
    }

    .item {
      @media (max-width: 1023px) {
        text-align: center;

        &:not(:last-child) {
          margin-bottom: 65px;
        }
      }

      &-title {
        margin-bottom: 25px;
        color: white;
        font-size: 10px;
        font-weight: 700;
        letter-spacing: 0.24em;
        text-transform: uppercase;
      }

      &-description {
        width: 185px;

        p {
          color: #afaab4;
          font-size: 12px;
          font-weight: 700;
          line-height: 1.6;
          letter-spacing: 0.04em;
        }
      }

      &-cta {
        margin-top: 15px;

        a {
          border-bottom: 1px solid white;
          padding-bottom: 5px;
          transition-property: border-color;
          transition-duration: time(default);
          transition-timing-function: ease(inout);

          span {
            margin-right: 10px;
            color: white;
            font-size: 10px;
            font-weight: 700;
            letter-spacing: 0.08em;
            text-transform: uppercase;
            transition-property: color;
            transition-duration: time(default);
            transition-timing-function: ease(inout);
          }

          svg path {
            transition-property: fill;
            transition-duration: time(default);
            transition-timing-function: ease(inout);
          }

          &:hover {
            border-color: #93b1d3;

            span {
              color: #93b1d3;
            }

            svg path {
              fill: #93b1d3;
            }
          }
        }
      }

      &-phone {
        &:not(:last-child) {
          margin-bottom: 25px;
        }

        a {
          color: #afaab4;
          font-size: 24px;
          font-weight: 400;
          letter-spacing: 0.04em;
          position: relative;
          display: flex;
          align-items: flex-end;
          transition-property: color;
          transition-duration: time(default);
          transition-timing-function: ease(inout);

          small {
            position: relative;
            top: -3px;
            margin-right: 3px;
            font-size: 12px;
            font-weight: 400;
          }

          span {
            position: absolute;
            font-size: 12px;
            font-weight: 700;
            bottom: -15px;

            @media (max-width: 1023px) {
              left: 50%;
              transform: translateX(-50%);
            }
          }

          &:hover {
            color: #93b1d3;
          }
        }
      }

      &-links {
        ul {
          li {
            &:not(:last-child) {
              margin-bottom: 10px;
            }

            a {
              color: #afaab4;
              font-size: 12px;
              font-weight: 700;
              letter-spacing: 0.04em;
              transition-property: color;
              transition-duration: time(default);
              transition-timing-function: ease(inout);

              &:hover {
                color: #93b1d3;
              }
            }
          }
        }
      }

      &-social {
        ul {
          @media (max-width: 1023px) {
            display: flex;
            align-items: center;
          }

          li {
            &:not(:last-child) {
              margin-bottom: 45px;

              @media (max-width: 1023px) {
                margin: 0 60px 0 0;
              }
            }

            a {
              svg path {
                transition-property: fill;
                transition-duration: time(default);
                transition-timing-function: ease(inout);
              }

              &:hover svg path {
                fill: #93b1d3;
              }
            }
          }
        }
      }
    }
  }

  .bottom {
    margin-top: 65px;
    display: flex;
    align-items: center;
    padding-bottom: 65px;

    @media (max-width: 1023px) {
      flex-direction: column;
      margin-top: 54px;
      padding: 0 0 100px;
    }

    .copyright {
      margin-right: 250px;
      color: #afaab4;
      font-size: 10px;
      font-weight: 700;
      text-transform: uppercase;
      opacity: 0.5;

      @media (max-width: 1023px) {
        margin: 0 0 40px;
        line-height: 1.5;
        text-align: center;
      }
    }

    .signature {
      a {
        display: flex;
        align-items: center;

        span {
          margin-right: 5px;
          color: #afaab4;
          font-size: 12px;
          font-weight: 700;
          opacity: 0.5;
          transition-property: color;
          transition-duration: time(default);
          transition-timing-function: ease(inout);
        }

        &:hover span {
          color: #93b1d3;
        }
      }
    }
  }
}
