%gradient {
  background-image: linear-gradient(to bottom right, #93b1d3, #afaab4);
}

%gradient-title {
  background-image: linear-gradient(to bottom right, #93b1d3, #afaab4);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding-bottom: 10px;
}

%gradient-button {
  background-image: linear-gradient(to bottom right, #93b1d3, #afaab4, #93b1d3);
  background-size: 200% 200%;
  background-position: top left;
  transition-property: background-position;
  transition-duration: time(default);
  transition-timing-function: ease(inout);

  &:hover {
    background-position: right bottom;
  }
}
