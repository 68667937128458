.appear-fade,
.appear-fade-horizontal,
.appear-fade-vertical {
  opacity: 0;
  transition-property: opacity, transform;
  transition-duration: 1.5s;
  transition-timing-function: ease(inout);

  &.appear {
    opacity: 1;
    transform: translate(0, 0);
  }
}

.appear-fade-vertical {
  transform: translate(0, 15px);
}

.appear-fade-horizontal {
  transform: translate(15px, 0);
}
