#main-menu {
  @extend %cover;

  background-color: black;
  position: fixed;
  opacity: 0;
  pointer-events: none;
  transition-property: opacity;
  transition-duration: time(default);
  transition-timing-function: ease(inout);
  z-index: 60;

  .logo {
    position: absolute;
    top: 40px;
    left: 25px;
    z-index: 5;
  }

  .close {
    position: absolute;
    top: 40px;
    right: 25px;
    z-index: 5;
  }

  &.sticky {
    .logo {
      top: 14px;
      left: 22px;
    }

    .close {
      top: 24px;
      right: 22px;
    }
  }

  .background {
    background: radial-gradient(
        78.23% 107.23% at 0% 49.48%,
        rgba(0, 85, 184, 0.3) 0%,
        rgba(0, 85, 184, 0) 100%
      ),
      #000000;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: calc(50vw + 200px);
    transform: matrix(-1, 0, 0, 1, 0, 0);
    z-index: 1;

    @media (max-width: 1023px) {
      display: none;
    }

    &::after {
      content: '';
      background-image: linear-gradient(
        to top,
        fade-out(#0055b8, 1),
        #0055b8,
        fade-out(#0055b8, 1)
      );
      position: absolute;
      top: 50%;
      left: 0;
      width: 20px;
      height: 50%;
      transform-origin: 50% 50%;
      transform: translateY(-50%) scaleY(0);
      transition-property: width, transform;
      transition-duration: time(slow);
      transition-timing-function: ease(inout);
    }
  }

  .container {
    position: relative;
    z-index: 2;
  }

  .flex-wrapper {
    display: flex;
    justify-content: space-between;
    height: 100vh;
    padding: 90px 100px 55px;

    @media (max-height: 710px) {
      padding: 50px 100px 50px 150px;
    }
    @media (max-height: 520px) {
      padding: 30px 100px 30px 150px;
    }
    @media (max-width: 1023px) {
      flex-direction: column;
      justify-content: flex-start;
      padding: 120px 0 0;
    }

    .left {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;

      &-navigation {
        ul:nth-child(1) {
          li {
            opacity: 0;
            transform: translateX(10px);
            transition-property: opacity, transform;
            transition-duration: time(default);
            transition-timing-function: ease(inout);

            &:not(:last-child) {
              margin-bottom: 64px;

              @media (max-height: 710px) {
                margin-bottom: 30px;
              }
              @media (max-width: 1023px) {
                margin: 0 0 12px;
              }
              @media (max-width: 355px) {
                margin: 0 0 8px;
              }
            }

            a {
              color: #93b1d3;
              font-family: font(title);
              font-size: 49px;
              font-weight: 300;
              letter-spacing: -0.02em;
              transition-property: color;
              transition-duration: time(default);
              transition-timing-function: ease(inout);

              @media (max-width: 1023px) {
                font-size: 32px;
              }
              @media (max-width: 355px) {
                font-size: 24px;
              }

              &:hover {
                color: white;
              }
            }
          }
        }

        ul:nth-child(2) {
          padding-top: 68px;
          margin-top: 68px;
          border-top: 1px solid fade-out(#93b1d3, 0.7);
          opacity: 0;
          transition-property: opacity;
          transition-duration: time(default);
          transition-timing-function: ease(inout);

          @media (max-height: 710px) {
            padding-top: 40px;
            margin-top: 40px;
          }
          @media (max-width: 1023px) {
            border: none;
            padding: 0;
            margin-top: 35px;
          }
          @media (max-width: 355px) {
            margin-top: 25px;
          }

          li {
            opacity: 0;
            transform: translateX(10px);
            transition-property: opacity, transform;
            transition-duration: time(default);
            transition-timing-function: ease(inout);

            &:not(:last-child) {
              margin-bottom: 16px;
            }

            a {
              color: #93b1d3;
              font-size: 16px;
              font-weight: normal;
              display: inline-block;
              letter-spacing: 0.02em;
              transition-property: color;
              transition-duration: time(default);
              transition-timing-function: ease(inout);

              &:hover {
                color: white;
              }
            }
          }
        }
      }

      &-actions {
        display: flex;
        align-items: center;

        @media (max-width: 1023px) {
          display: none;
        }

        .action {
          opacity: 0;
          transform: translateX(10px);
          transition-property: opacity, transform;
          transition-duration: time(default);
          transition-timing-function: ease(inout);

          &:not(:last-child) {
            margin-right: 16px;
          }

          a {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            height: 48px;
            padding: 1px 16px 0;
            border: 1px solid #afaab4;
            border-radius: 24px;
            transition-property: border-color;
            transition-duration: time(default);
            transition-timing-function: ease(inout);

            &::before {
              @extend %gradient-button;

              content: '';
              position: absolute;
              top: -1px;
              right: -1px;
              bottom: -1px;
              left: -1px;
              border-radius: 50px;
              overflow: hidden;
              opacity: 0;
              transition-property: opacity;
              transition-duration: time(default);
              transition-timing-function: ease(inout);
              z-index: 1;
            }

            span {
              margin-right: 15px;
              color: white;
              font-size: 10px;
              font-weight: 700;
              text-transform: uppercase;
              letter-spacing: 0.08em;
              position: relative;
              z-index: 2;
            }

            svg {
              position: relative;
              z-index: 2;
            }

            &:hover {
              border-color: transparent;

              &::before {
                opacity: 1;
              }
            }
          }
        }
      }

      &-bottom {
        display: flex;
        align-items: center;
        opacity: 0;
        transition-property: opacity;
        transition-duration: time(default);
        transition-timing-function: ease(inout);

        @media (max-width: 1023px) {
          display: none;
        }

        .copyright {
          color: #afaab4;
          font-size: 10px;
          font-weight: 700;
          text-transform: uppercase;
          opacity: 0.5;
          margin-right: 105px;
        }

        .signature {
          a {
            display: flex;
            align-items: center;

            span {
              margin-right: 5px;
              color: #afaab4;
              font-size: 12px;
              font-weight: 700;
              opacity: 0.5;
              transition-property: color;
              transition-duration: time(default);
              transition-timing-function: ease(inout);
            }

            &:hover span {
              color: #93b1d3;
            }
          }
        }
      }
    }

    .right {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;

      @media (max-width: 1023px) {
        margin-top: 35px;
      }
      @media (max-width: 355px) {
        margin-top: 25px;
      }

      &-actions {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .action {
          position: relative;
          display: flex;
          align-items: center;
          opacity: 0;
          transform: translateX(10px);
          transition-property: opacity, transform;
          transition-duration: time(default);
          transition-timing-function: ease(inout);

          &:not(:last-child) {
            margin-bottom: 24px;

            @media (max-height: 590px) {
              margin-bottom: 15px;
            }
            @media (max-width: 1023px) {
              margin: 0 0 8px;
            }
          }

          &-link {
            @extend %cover;

            z-index: 5;
          }

          &-icon {
            position: relative;
            width: 48px;
            height: 48px;
            flex-shrink: 0;
            margin-right: 8px;

            picture,
            img {
              display: block;
            }

            svg {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              transition-property: transform;
              transition-duration: time(fast);
              transition-timing-function: ease(inout);
              z-index: 2;
            }
          }

          &-content {
            &--title {
              color: #93b1d3;
              font-size: 10px;
              font-weight: bold;
              text-transform: uppercase;
              letter-spacing: 0.08em;
              margin-bottom: 5px;

              @media (max-width: 1023px) {
                margin: 0;
              }
            }

            &--subtitle {
              color: white;
              font-size: 10px;
              font-weight: bold;
              text-transform: uppercase;
              letter-spacing: 0.08em;
              transition-property: color;
              transition-duration: time(fast);
              transition-timing-function: ease(inout);

              @media (max-width: 1023px) {
                display: none;
              }
            }

            &--phone {
              color: white;
              font-size: 16px;
              font-weight: normal;
              text-transform: uppercase;
              letter-spacing: 0.08em;
              transition-property: color;
              transition-duration: time(fast);
              transition-timing-function: ease(inout);

              @media (max-width: 1023px) {
                display: none;
              }

              small {
                font-size: 10px;
                font-weight: bold;
              }
            }
          }

          &:hover {
            .action-icon svg {
              transform: translate(-50%, -50%) scale(1.2);
            }

            .action-content {
              > * {
                color: #93b1d3;
              }
            }
          }
        }
      }

      &-location {
        opacity: 0;
        transition-property: opacity;
        transition-duration: time(default);
        transition-timing-function: ease(inout);

        @media (max-width: 1023px) {
          display: none;
        }

        &--title {
          color: white;
          font-size: 10px;
          font-weight: 700;
          letter-spacing: 0.24em;
          text-transform: uppercase;
          margin-bottom: 25px;

          @media (max-height: 590px) {
            margin-bottom: 15px;
          }
        }

        &--description {
          width: 185px;

          p {
            color: #93b1d3;
            font-size: 12px;
            font-weight: 700;
            line-height: 1.6;
            letter-spacing: 0.04em;
          }
        }

        &--cta {
          margin-top: 15px;

          @media (max-height: 590px) {
            margin-top: 10px;
          }

          a {
            border-bottom: 1px solid white;
            padding-bottom: 5px;
            transition-property: border-color;
            transition-duration: time(default);
            transition-timing-function: ease(inout);

            span {
              margin-right: 10px;
              color: white;
              font-size: 10px;
              font-weight: 700;
              letter-spacing: 0.08em;
              text-transform: uppercase;
              transition-property: color;
              transition-duration: time(default);
              transition-timing-function: ease(inout);
            }

            svg path {
              transition-property: fill;
              transition-duration: time(default);
              transition-timing-function: ease(inout);
            }

            &:hover {
              border-color: #93b1d3;

              span {
                color: #93b1d3;
              }

              svg path {
                fill: #93b1d3;
              }
            }
          }
        }
      }

      &-social {
        opacity: 0;
        transition-property: opacity;
        transition-duration: time(default);
        transition-timing-function: ease(inout);

        @media (max-width: 1023px) {
          margin-top: 35px;
        }
        @media (max-width: 355px) {
          margin-top: 25px;
        }

        ul {
          display: flex;
          align-items: center;

          li {
            &:not(:last-child) {
              margin-right: 30px;
            }

            a {
              display: inline-block;

              svg path {
                transition-property: fill;
                transition-duration: time(default);
                transition-timing-function: ease(inout);
              }

              &:hover svg path {
                fill: #93b1d3;
              }
            }
          }
        }
      }
    }
  }

  &.open {
    opacity: 1;
    pointer-events: initial;

    .background {
      &::after {
        width: 1px;
        transform: translateY(-50%) scaleY(1);
        transition-delay: 0.2s;
      }
    }

    .flex-wrapper {
      .left {
        &-navigation {
          ul:nth-child(1) li {
            opacity: 1;
            transform: translateX(0);

            @for $i from 0 to 5 {
              &:nth-child(#{$i}) {
                transition-delay: 0.1s * $i + 0.3s;
              }
            }
          }

          ul:nth-child(2) {
            opacity: 1;
            transition-delay: 0.5s;

            li {
              opacity: 1;
              transform: translateX(0);

              @for $i from 0 to 5 {
                &:nth-child(#{$i}) {
                  transition-delay: 0.1s * $i + 0.5s;
                }
              }
            }
          }
        }

        &-actions {
          .action {
            opacity: 1;
            transform: translateX(0);

            @for $i from 0 to 5 {
              &:nth-child(#{$i}) {
                transition-delay: 0.1s * $i + 0.5s;
              }
            }
          }
        }

        &-bottom {
          opacity: 1;
          transition-delay: 0.8s;
        }
      }

      .right {
        &-actions {
          .action {
            opacity: 1;
            transform: translateY(0);

            @for $i from 0 to 5 {
              &:nth-child(#{$i}) {
                transition-delay: 0.1s * $i + 0.8s;
              }
            }
          }
        }

        &-location {
          opacity: 1;
          transition-delay: 1.1s;
        }

        &-social {
          opacity: 1;
          transition-delay: 1.2s;
        }
      }
    }
  }
}
