#main-actions .actions::before, #main-actions .actions .action-link, #main-menu, #main-menu .flex-wrapper .right-actions .action-link, .modal-call, .modal-call .background img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover; }

.screen-progress {
  background-image: linear-gradient(to bottom right, #93b1d3, #afaab4); }

.slide-arrow, #main-footer .headline-actions .action a::before, #main-actions .toggle button, #main-menu .flex-wrapper .left-actions .action a::before, .modal-call .wrapper-form .form-submit button {
  background-image: linear-gradient(to bottom right, #93b1d3, #afaab4, #93b1d3);
  background-size: 200% 200%;
  background-position: top left;
  transition-property: background-position;
  transition-duration: 0.7s;
  transition-timing-function: cubic-bezier(0.5, 0, 0, 1); }
  .slide-arrow:hover, #main-footer .headline-actions .action a:hover::before, #main-actions .toggle button:hover, #main-menu .flex-wrapper .left-actions .action a:hover::before, .modal-call .wrapper-form .form-submit button:hover {
    background-position: right bottom; }

/**
* Base.
*/
@font-face {
  font-family: 'Canela';
  src: url("../../assets/fonts/Canela-Regular.woff2") format("woff2"), url("../../assets/fonts/Canela-Regular.woff") format("woff"), url("../../assets/fonts/Canela-Regular.svg#Canela-Regular") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Product Sans';
  src: url("../../assets/fonts/ProductSans-Bold.woff2") format("woff2"), url("../../assets/fonts/ProductSans-Bold.woff") format("woff"), url("../../assets/fonts/ProductSans-Bold.svg#ProductSans-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Product Sans';
  src: url("../../assets/fonts/ProductSans-Regular.woff2") format("woff2"), url("../../assets/fonts/ProductSans-Regular.woff") format("woff"), url("../../assets/fonts/ProductSans-Regular.svg#ProductSans-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

body {
  font-family: "Canela";
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  body::before {
    content: '';
    background-color: black;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    opacity: 0;
    pointer-events: none;
    transition: opacity 600ms; }
  body.shadow {
    width: 100%;
    overflow: hidden; }
    body.shadow::before {
      opacity: 0.6;
      pointer-events: initial; }
  body.modal-open, body.menu-open {
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden; }

.container {
  width: 100%;
  max-width: 1170px;
  margin: 0 auto; }
  @media (max-width: 1350px) {
    .container {
      padding: 0 25px; } }

* {
  font-family: "Product Sans";
  line-height: 100%;
  text-decoration: none;
  margin: 0;
  padding: 0;
  box-sizing: border-box !important;
  list-style-type: none; }

form {
  font-family: "Product Sans"; }
  form input {
    line-height: initial; }
  form input,
  form textarea,
  form select {
    outline: none !important; }
    form input:active, form input:focus,
    form textarea:active,
    form textarea:focus,
    form select:active,
    form select:focus {
      outline: none !important; }

a,
a:link,
a:hover,
a:active,
a:visited,
a:focus {
  text-decoration: none !important;
  outline: none !important; }

button {
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer; }

button,
button:active,
button:focus {
  outline: none !important; }

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 100%;
  margin: 0; }

ul,
ol {
  margin: 0;
  list-style-type: none; }

label {
  font-weight: normal; }

/** Transition slide. */
@keyframes fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.fade-in {
  animation-name: fade-in;
  animation-fill-mode: forwards;
  animation-duration: 1000ms; }

@keyframes fade-out {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fade-out {
  animation-name: fade-out;
  animation-fill-mode: forwards;
  animation-duration: 1000ms; }

.slide-arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  overflow: hidden; }
  .slide-arrow svg path {
    fill: black; }

.appear-fade,
.appear-fade-horizontal,
.appear-fade-vertical {
  opacity: 0;
  transition-property: opacity, transform;
  transition-duration: 1.5s;
  transition-timing-function: cubic-bezier(0.5, 0, 0, 1); }
  .appear-fade.appear,
  .appear-fade-horizontal.appear,
  .appear-fade-vertical.appear {
    opacity: 1;
    transform: translate(0, 0); }

.appear-fade-vertical {
  transform: translate(0, 15px); }

.appear-fade-horizontal {
  transform: translate(15px, 0); }

[rel='lazy_load'] {
  overflow: hidden; }

@keyframes lazy_load {
  0% {
    transform: scaleX(0);
    transform-origin: 0% 50%; }
  50% {
    transform: scaleX(1);
    transform-origin: 0% 50%; }
  50.1% {
    transform: scaleX(1);
    transform-origin: 100% 50%; }
  100% {
    transform: scaleX(0);
    transform-origin: 100% 50%; } }
  [rel='lazy_load']:not(.no-animate) img {
    z-index: 1;
    transform-origin: 25% 25%;
    opacity: 0;
    transform: scale(1.08);
    transition-property: opacity, transform;
    transition-duration: 2s;
    transition-timing-function: cubic-bezier(0.5, 0, 0, 1); }
  [rel='lazy_load'].no-animate img {
    opacity: 0;
    transition-property: opacity;
    transition-duration: 1s;
    transition-timing-function: ease; }
  [rel='lazy_load'].loaded:not(.no-animate) img {
    opacity: 1;
    transform: scale(1); }
  [rel='lazy_load'].loaded.no-animate img {
    opacity: 1; }

.screen-progress {
  color: black;
  text-align: center;
  min-width: 300px;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 100;
  margin-top: 15px;
  padding: 40px 20px 32px;
  opacity: 0;
  transform: translate(-50%, -50%);
  transition: margin-top 0.7s cubic-bezier(0.5, 0, 0, 1), opacity 0.7s cubic-bezier(0, 0, 0.3, 1);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1); }
  .screen-progress.open {
    margin-top: 0;
    opacity: 1; }
  .screen-progress .screen-message {
    font-size: 16px;
    font-weight: normal;
    margin: 0;
    margin-bottom: 15px; }
  .screen-progress .spinner {
    display: inline-block;
    width: 40px;
    height: 40px;
    position: relative; }

@keyframes progress_spinner {
  0% {
    transform: scale(0);
    opacity: 0; }
  50% {
    opacity: 0.3; }
  100% {
    transform: scale(1);
    opacity: 0; } }
    .screen-progress .spinner span {
      background-color: black;
      border-radius: 50%;
      display: inline-block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: 0;
      transform: scale(0);
      animation: progress_spinner 1.5s infinite; }
      .screen-progress .spinner span:nth-child(2) {
        animation-delay: 0.5s; }

/**
* Globals.
*/
#main-header {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  pointer-events: none;
  z-index: 50; }
  #main-header .flex-wrapper {
    padding: 40px 25px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    transition-property: padding;
    transition-duration: 0.7s;
    transition-timing-function: cubic-bezier(0.5, 0, 0, 1); }
    #main-header .flex-wrapper .logo a {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 112px;
      height: 52px;
      transition-property: background-color, width, height;
      transition-duration: 0.7s;
      transition-timing-function: cubic-bezier(0.5, 0, 0, 1); }
    #main-header .flex-wrapper .menu button {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 48px;
      height: 28px;
      transition-property: background-color, width, height;
      transition-duration: 0.7s;
      transition-timing-function: cubic-bezier(0.5, 0, 0, 1); }
    #main-header .flex-wrapper > * {
      pointer-events: initial; }
  #main-header.sticky .flex-wrapper {
    padding: 0; }
    #main-header.sticky .flex-wrapper .logo a {
      background-color: black;
      width: 155px;
      height: 80px; }
    #main-header.sticky .flex-wrapper .menu button {
      background-color: black;
      width: 80px;
      height: 80px; }

#main-footer {
  background-color: black; }
  #main-footer .container {
    position: relative; }
    #main-footer .container > * {
      position: relative;
      z-index: 2; }
  #main-footer .background {
    background: linear-gradient(244.09deg, #001834 0%, rgba(0, 24, 52, 0) 36%);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: calc(100vw - ((100vw - 1170px) / 2));
    z-index: 1; }
    @media (max-width: 1023px) {
      #main-footer .background {
        display: none; } }
  #main-footer .headline {
    padding-top: 110px;
    width: calc(100% - 100px);
    display: flex;
    align-items: center;
    justify-content: space-between; }
    @media (max-width: 1023px) {
      #main-footer .headline {
        display: none; } }
    #main-footer .headline-title {
      color: #93b1d3;
      font-size: 80px;
      font-weight: 300;
      font-family: "Canela";
      letter-spacing: -0.02em;
      opacity: 0.2; }
    #main-footer .headline-actions {
      display: flex;
      align-items: center; }
      #main-footer .headline-actions .action:not(:last-child) {
        margin-right: 45px; }
      #main-footer .headline-actions .action a {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        height: 48px;
        padding: 1px 16px 0;
        border: 1px solid #afaab4;
        border-radius: 24px;
        transition-property: border-color;
        transition-duration: 0.7s;
        transition-timing-function: cubic-bezier(0.5, 0, 0, 1); }
        #main-footer .headline-actions .action a::before {
          content: '';
          position: absolute;
          top: -1px;
          right: -1px;
          bottom: -1px;
          left: -1px;
          border-radius: 50px;
          overflow: hidden;
          opacity: 0;
          transition-property: opacity;
          transition-duration: 0.7s;
          transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
          z-index: 1; }
        #main-footer .headline-actions .action a span {
          margin-right: 15px;
          color: white;
          font-size: 10px;
          font-weight: 700;
          text-transform: uppercase;
          letter-spacing: 0.08em;
          position: relative;
          z-index: 2; }
        #main-footer .headline-actions .action a svg {
          position: relative;
          z-index: 2; }
        #main-footer .headline-actions .action a:hover {
          border-color: transparent; }
          #main-footer .headline-actions .action a:hover::before {
            opacity: 1; }
  #main-footer .flex-wrapper {
    width: calc(100% - 100px);
    border-top: 1px solid rgba(147, 177, 211, 0.2);
    margin-top: 70px;
    padding-top: 70px;
    display: flex;
    justify-content: space-between; }
    @media (max-width: 1023px) {
      #main-footer .flex-wrapper {
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin: 0;
        padding: 100px 0 0;
        border: none; } }
    @media (max-width: 1023px) {
      #main-footer .flex-wrapper .item {
        text-align: center; }
        #main-footer .flex-wrapper .item:not(:last-child) {
          margin-bottom: 65px; } }
    #main-footer .flex-wrapper .item-title {
      margin-bottom: 25px;
      color: white;
      font-size: 10px;
      font-weight: 700;
      letter-spacing: 0.24em;
      text-transform: uppercase; }
    #main-footer .flex-wrapper .item-description {
      width: 185px; }
      #main-footer .flex-wrapper .item-description p {
        color: #afaab4;
        font-size: 12px;
        font-weight: 700;
        line-height: 1.6;
        letter-spacing: 0.04em; }
    #main-footer .flex-wrapper .item-cta {
      margin-top: 15px; }
      #main-footer .flex-wrapper .item-cta a {
        border-bottom: 1px solid white;
        padding-bottom: 5px;
        transition-property: border-color;
        transition-duration: 0.7s;
        transition-timing-function: cubic-bezier(0.5, 0, 0, 1); }
        #main-footer .flex-wrapper .item-cta a span {
          margin-right: 10px;
          color: white;
          font-size: 10px;
          font-weight: 700;
          letter-spacing: 0.08em;
          text-transform: uppercase;
          transition-property: color;
          transition-duration: 0.7s;
          transition-timing-function: cubic-bezier(0.5, 0, 0, 1); }
        #main-footer .flex-wrapper .item-cta a svg path {
          transition-property: fill;
          transition-duration: 0.7s;
          transition-timing-function: cubic-bezier(0.5, 0, 0, 1); }
        #main-footer .flex-wrapper .item-cta a:hover {
          border-color: #93b1d3; }
          #main-footer .flex-wrapper .item-cta a:hover span {
            color: #93b1d3; }
          #main-footer .flex-wrapper .item-cta a:hover svg path {
            fill: #93b1d3; }
    #main-footer .flex-wrapper .item-phone:not(:last-child) {
      margin-bottom: 25px; }
    #main-footer .flex-wrapper .item-phone a {
      color: #afaab4;
      font-size: 24px;
      font-weight: 400;
      letter-spacing: 0.04em;
      position: relative;
      display: flex;
      align-items: flex-end;
      transition-property: color;
      transition-duration: 0.7s;
      transition-timing-function: cubic-bezier(0.5, 0, 0, 1); }
      #main-footer .flex-wrapper .item-phone a small {
        position: relative;
        top: -3px;
        margin-right: 3px;
        font-size: 12px;
        font-weight: 400; }
      #main-footer .flex-wrapper .item-phone a span {
        position: absolute;
        font-size: 12px;
        font-weight: 700;
        bottom: -15px; }
        @media (max-width: 1023px) {
          #main-footer .flex-wrapper .item-phone a span {
            left: 50%;
            transform: translateX(-50%); } }
      #main-footer .flex-wrapper .item-phone a:hover {
        color: #93b1d3; }
    #main-footer .flex-wrapper .item-links ul li:not(:last-child) {
      margin-bottom: 10px; }
    #main-footer .flex-wrapper .item-links ul li a {
      color: #afaab4;
      font-size: 12px;
      font-weight: 700;
      letter-spacing: 0.04em;
      transition-property: color;
      transition-duration: 0.7s;
      transition-timing-function: cubic-bezier(0.5, 0, 0, 1); }
      #main-footer .flex-wrapper .item-links ul li a:hover {
        color: #93b1d3; }
    @media (max-width: 1023px) {
      #main-footer .flex-wrapper .item-social ul {
        display: flex;
        align-items: center; } }
    #main-footer .flex-wrapper .item-social ul li:not(:last-child) {
      margin-bottom: 45px; }
      @media (max-width: 1023px) {
        #main-footer .flex-wrapper .item-social ul li:not(:last-child) {
          margin: 0 60px 0 0; } }
    #main-footer .flex-wrapper .item-social ul li a svg path {
      transition-property: fill;
      transition-duration: 0.7s;
      transition-timing-function: cubic-bezier(0.5, 0, 0, 1); }
    #main-footer .flex-wrapper .item-social ul li a:hover svg path {
      fill: #93b1d3; }
  #main-footer .bottom {
    margin-top: 65px;
    display: flex;
    align-items: center;
    padding-bottom: 65px; }
    @media (max-width: 1023px) {
      #main-footer .bottom {
        flex-direction: column;
        margin-top: 54px;
        padding: 0 0 100px; } }
    #main-footer .bottom .copyright {
      margin-right: 250px;
      color: #afaab4;
      font-size: 10px;
      font-weight: 700;
      text-transform: uppercase;
      opacity: 0.5; }
      @media (max-width: 1023px) {
        #main-footer .bottom .copyright {
          margin: 0 0 40px;
          line-height: 1.5;
          text-align: center; } }
    #main-footer .bottom .signature a {
      display: flex;
      align-items: center; }
      #main-footer .bottom .signature a span {
        margin-right: 5px;
        color: #afaab4;
        font-size: 12px;
        font-weight: 700;
        opacity: 0.5;
        transition-property: color;
        transition-duration: 0.7s;
        transition-timing-function: cubic-bezier(0.5, 0, 0, 1); }
      #main-footer .bottom .signature a:hover span {
        color: #93b1d3; }

#main-actions {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 290px;
  pointer-events: none;
  z-index: 50; }
  @media (max-width: 1023px) {
    #main-actions {
      width: 100%; } }
  #main-actions .toggle {
    position: relative;
    width: 100%;
    height: 60px;
    pointer-events: initial;
    z-index: 1; }
    #main-actions .toggle button {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      width: 100%;
      height: 100%;
      padding: 1px 0 0; }
      #main-actions .toggle button span {
        color: black;
        font-size: 10px;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 0.08em;
        display: inline-block;
        margin-right: 16px; }
      #main-actions .toggle button svg path {
        fill: black; }
  #main-actions .actions {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    bottom: 60px;
    width: 100%;
    padding: 32px 48px;
    z-index: 2; }
    #main-actions .actions::before {
      content: '';
      background-color: black;
      transform-origin: 50% 100%;
      transform: scaleY(0);
      transition-property: transform;
      transition-duration: 0.5s;
      transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
      transition-delay: 0.4s;
      z-index: 1; }
    #main-actions .actions::after {
      content: '';
      background-color: black;
      position: absolute;
      bottom: -12px;
      left: 50%;
      transform: translate(-50%, -13px);
      width: 26px;
      height: 13px;
      clip-path: polygon(0 0, 100% 0, 50% 100%);
      opacity: 0;
      transition-property: opacity, transform;
      transition-duration: 0.5s;
      transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
      transition-delay: 0.4s;
      z-index: 3; }
    #main-actions .actions .action {
      position: relative;
      display: flex;
      align-items: center;
      opacity: 0;
      transform: translateY(10px);
      transition-property: opacity, transform;
      transition-duration: 0.5s;
      transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
      z-index: 2; }
      #main-actions .actions .action:nth-child(0) {
        transition-delay: 0s; }
      #main-actions .actions .action:nth-child(1) {
        transition-delay: 0.1s; }
      #main-actions .actions .action:nth-child(2) {
        transition-delay: 0.2s; }
      #main-actions .actions .action:nth-child(3) {
        transition-delay: 0.3s; }
      #main-actions .actions .action:nth-child(4) {
        transition-delay: 0.4s; }
      #main-actions .actions .action:not(:last-child) {
        margin-bottom: 24px; }
      #main-actions .actions .action-link {
        z-index: 5; }
      #main-actions .actions .action-icon {
        position: relative;
        width: 48px;
        height: 48px;
        flex-shrink: 0;
        margin-right: 8px; }
        #main-actions .actions .action-icon picture,
        #main-actions .actions .action-icon img {
          display: block; }
        #main-actions .actions .action-icon svg {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          transition-property: transform;
          transition-duration: 0.5s;
          transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
          z-index: 2; }
      #main-actions .actions .action-content--title {
        color: #93b1d3;
        font-size: 10px;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 0.08em;
        margin-bottom: 5px; }
      #main-actions .actions .action-content--subtitle {
        color: white;
        font-size: 10px;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 0.08em;
        transition-property: color;
        transition-duration: 0.5s;
        transition-timing-function: cubic-bezier(0.5, 0, 0, 1); }
      #main-actions .actions .action-content--phone {
        color: white;
        font-size: 16px;
        font-weight: normal;
        text-transform: uppercase;
        letter-spacing: 0.08em;
        transition-property: color;
        transition-duration: 0.5s;
        transition-timing-function: cubic-bezier(0.5, 0, 0, 1); }
        #main-actions .actions .action-content--phone small {
          font-size: 10px;
          font-weight: bold; }
      #main-actions .actions .action:hover .action-icon svg {
        transform: translate(-50%, -50%) scale(1.2); }
      #main-actions .actions .action:hover .action-content > * {
        color: #93b1d3; }
  #main-actions.open .actions {
    pointer-events: initial; }
    #main-actions.open .actions::before {
      transform: scaleY(1);
      transition-delay: 0s; }
    #main-actions.open .actions::after {
      opacity: 1;
      transform: translate(-50%, 0);
      transition-delay: 0s; }
    #main-actions.open .actions .action {
      opacity: 1;
      transform: translateY(0); }
      #main-actions.open .actions .action:nth-child(1) {
        transition-delay: 0.4s; }
      #main-actions.open .actions .action:nth-child(2) {
        transition-delay: 0.3s; }
      #main-actions.open .actions .action:nth-child(3) {
        transition-delay: 0.2s; }
      #main-actions.open .actions .action:nth-child(4) {
        transition-delay: 0.1s; }

#main-menu {
  background-color: black;
  position: fixed;
  opacity: 0;
  pointer-events: none;
  transition-property: opacity;
  transition-duration: 0.7s;
  transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
  z-index: 60; }
  #main-menu .logo {
    position: absolute;
    top: 40px;
    left: 25px;
    z-index: 5; }
  #main-menu .close {
    position: absolute;
    top: 40px;
    right: 25px;
    z-index: 5; }
  #main-menu.sticky .logo {
    top: 14px;
    left: 22px; }
  #main-menu.sticky .close {
    top: 24px;
    right: 22px; }
  #main-menu .background {
    background: radial-gradient(78.23% 107.23% at 0% 49.48%, rgba(0, 85, 184, 0.3) 0%, rgba(0, 85, 184, 0) 100%), #000000;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: calc(50vw + 200px);
    transform: matrix(-1, 0, 0, 1, 0, 0);
    z-index: 1; }
    @media (max-width: 1023px) {
      #main-menu .background {
        display: none; } }
    #main-menu .background::after {
      content: '';
      background-image: linear-gradient(to top, rgba(0, 85, 184, 0), #0055b8, rgba(0, 85, 184, 0));
      position: absolute;
      top: 50%;
      left: 0;
      width: 20px;
      height: 50%;
      transform-origin: 50% 50%;
      transform: translateY(-50%) scaleY(0);
      transition-property: width, transform;
      transition-duration: 1s;
      transition-timing-function: cubic-bezier(0.5, 0, 0, 1); }
  #main-menu .container {
    position: relative;
    z-index: 2; }
  #main-menu .flex-wrapper {
    display: flex;
    justify-content: space-between;
    height: 100vh;
    padding: 90px 100px 55px; }
    @media (max-height: 710px) {
      #main-menu .flex-wrapper {
        padding: 50px 100px 50px 150px; } }
    @media (max-height: 520px) {
      #main-menu .flex-wrapper {
        padding: 30px 100px 30px 150px; } }
    @media (max-width: 1023px) {
      #main-menu .flex-wrapper {
        flex-direction: column;
        justify-content: flex-start;
        padding: 120px 0 0; } }
    #main-menu .flex-wrapper .left {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between; }
      #main-menu .flex-wrapper .left-navigation ul:nth-child(1) li {
        opacity: 0;
        transform: translateX(10px);
        transition-property: opacity, transform;
        transition-duration: 0.7s;
        transition-timing-function: cubic-bezier(0.5, 0, 0, 1); }
        #main-menu .flex-wrapper .left-navigation ul:nth-child(1) li:not(:last-child) {
          margin-bottom: 64px; }
          @media (max-height: 710px) {
            #main-menu .flex-wrapper .left-navigation ul:nth-child(1) li:not(:last-child) {
              margin-bottom: 30px; } }
          @media (max-width: 1023px) {
            #main-menu .flex-wrapper .left-navigation ul:nth-child(1) li:not(:last-child) {
              margin: 0 0 12px; } }
          @media (max-width: 355px) {
            #main-menu .flex-wrapper .left-navigation ul:nth-child(1) li:not(:last-child) {
              margin: 0 0 8px; } }
        #main-menu .flex-wrapper .left-navigation ul:nth-child(1) li a {
          color: #93b1d3;
          font-family: "Canela";
          font-size: 49px;
          font-weight: 300;
          letter-spacing: -0.02em;
          transition-property: color;
          transition-duration: 0.7s;
          transition-timing-function: cubic-bezier(0.5, 0, 0, 1); }
          @media (max-width: 1023px) {
            #main-menu .flex-wrapper .left-navigation ul:nth-child(1) li a {
              font-size: 32px; } }
          @media (max-width: 355px) {
            #main-menu .flex-wrapper .left-navigation ul:nth-child(1) li a {
              font-size: 24px; } }
          #main-menu .flex-wrapper .left-navigation ul:nth-child(1) li a:hover {
            color: white; }
      #main-menu .flex-wrapper .left-navigation ul:nth-child(2) {
        padding-top: 68px;
        margin-top: 68px;
        border-top: 1px solid rgba(147, 177, 211, 0.3);
        opacity: 0;
        transition-property: opacity;
        transition-duration: 0.7s;
        transition-timing-function: cubic-bezier(0.5, 0, 0, 1); }
        @media (max-height: 710px) {
          #main-menu .flex-wrapper .left-navigation ul:nth-child(2) {
            padding-top: 40px;
            margin-top: 40px; } }
        @media (max-width: 1023px) {
          #main-menu .flex-wrapper .left-navigation ul:nth-child(2) {
            border: none;
            padding: 0;
            margin-top: 35px; } }
        @media (max-width: 355px) {
          #main-menu .flex-wrapper .left-navigation ul:nth-child(2) {
            margin-top: 25px; } }
        #main-menu .flex-wrapper .left-navigation ul:nth-child(2) li {
          opacity: 0;
          transform: translateX(10px);
          transition-property: opacity, transform;
          transition-duration: 0.7s;
          transition-timing-function: cubic-bezier(0.5, 0, 0, 1); }
          #main-menu .flex-wrapper .left-navigation ul:nth-child(2) li:not(:last-child) {
            margin-bottom: 16px; }
          #main-menu .flex-wrapper .left-navigation ul:nth-child(2) li a {
            color: #93b1d3;
            font-size: 16px;
            font-weight: normal;
            display: inline-block;
            letter-spacing: 0.02em;
            transition-property: color;
            transition-duration: 0.7s;
            transition-timing-function: cubic-bezier(0.5, 0, 0, 1); }
            #main-menu .flex-wrapper .left-navigation ul:nth-child(2) li a:hover {
              color: white; }
      #main-menu .flex-wrapper .left-actions {
        display: flex;
        align-items: center; }
        @media (max-width: 1023px) {
          #main-menu .flex-wrapper .left-actions {
            display: none; } }
        #main-menu .flex-wrapper .left-actions .action {
          opacity: 0;
          transform: translateX(10px);
          transition-property: opacity, transform;
          transition-duration: 0.7s;
          transition-timing-function: cubic-bezier(0.5, 0, 0, 1); }
          #main-menu .flex-wrapper .left-actions .action:not(:last-child) {
            margin-right: 16px; }
          #main-menu .flex-wrapper .left-actions .action a {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            height: 48px;
            padding: 1px 16px 0;
            border: 1px solid #afaab4;
            border-radius: 24px;
            transition-property: border-color;
            transition-duration: 0.7s;
            transition-timing-function: cubic-bezier(0.5, 0, 0, 1); }
            #main-menu .flex-wrapper .left-actions .action a::before {
              content: '';
              position: absolute;
              top: -1px;
              right: -1px;
              bottom: -1px;
              left: -1px;
              border-radius: 50px;
              overflow: hidden;
              opacity: 0;
              transition-property: opacity;
              transition-duration: 0.7s;
              transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
              z-index: 1; }
            #main-menu .flex-wrapper .left-actions .action a span {
              margin-right: 15px;
              color: white;
              font-size: 10px;
              font-weight: 700;
              text-transform: uppercase;
              letter-spacing: 0.08em;
              position: relative;
              z-index: 2; }
            #main-menu .flex-wrapper .left-actions .action a svg {
              position: relative;
              z-index: 2; }
            #main-menu .flex-wrapper .left-actions .action a:hover {
              border-color: transparent; }
              #main-menu .flex-wrapper .left-actions .action a:hover::before {
                opacity: 1; }
      #main-menu .flex-wrapper .left-bottom {
        display: flex;
        align-items: center;
        opacity: 0;
        transition-property: opacity;
        transition-duration: 0.7s;
        transition-timing-function: cubic-bezier(0.5, 0, 0, 1); }
        @media (max-width: 1023px) {
          #main-menu .flex-wrapper .left-bottom {
            display: none; } }
        #main-menu .flex-wrapper .left-bottom .copyright {
          color: #afaab4;
          font-size: 10px;
          font-weight: 700;
          text-transform: uppercase;
          opacity: 0.5;
          margin-right: 105px; }
        #main-menu .flex-wrapper .left-bottom .signature a {
          display: flex;
          align-items: center; }
          #main-menu .flex-wrapper .left-bottom .signature a span {
            margin-right: 5px;
            color: #afaab4;
            font-size: 12px;
            font-weight: 700;
            opacity: 0.5;
            transition-property: color;
            transition-duration: 0.7s;
            transition-timing-function: cubic-bezier(0.5, 0, 0, 1); }
          #main-menu .flex-wrapper .left-bottom .signature a:hover span {
            color: #93b1d3; }
    #main-menu .flex-wrapper .right {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between; }
      @media (max-width: 1023px) {
        #main-menu .flex-wrapper .right {
          margin-top: 35px; } }
      @media (max-width: 355px) {
        #main-menu .flex-wrapper .right {
          margin-top: 25px; } }
      #main-menu .flex-wrapper .right-actions {
        display: flex;
        flex-direction: column;
        align-items: flex-start; }
        #main-menu .flex-wrapper .right-actions .action {
          position: relative;
          display: flex;
          align-items: center;
          opacity: 0;
          transform: translateX(10px);
          transition-property: opacity, transform;
          transition-duration: 0.7s;
          transition-timing-function: cubic-bezier(0.5, 0, 0, 1); }
          #main-menu .flex-wrapper .right-actions .action:not(:last-child) {
            margin-bottom: 24px; }
            @media (max-height: 590px) {
              #main-menu .flex-wrapper .right-actions .action:not(:last-child) {
                margin-bottom: 15px; } }
            @media (max-width: 1023px) {
              #main-menu .flex-wrapper .right-actions .action:not(:last-child) {
                margin: 0 0 8px; } }
          #main-menu .flex-wrapper .right-actions .action-link {
            z-index: 5; }
          #main-menu .flex-wrapper .right-actions .action-icon {
            position: relative;
            width: 48px;
            height: 48px;
            flex-shrink: 0;
            margin-right: 8px; }
            #main-menu .flex-wrapper .right-actions .action-icon picture,
            #main-menu .flex-wrapper .right-actions .action-icon img {
              display: block; }
            #main-menu .flex-wrapper .right-actions .action-icon svg {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              transition-property: transform;
              transition-duration: 0.5s;
              transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
              z-index: 2; }
          #main-menu .flex-wrapper .right-actions .action-content--title {
            color: #93b1d3;
            font-size: 10px;
            font-weight: bold;
            text-transform: uppercase;
            letter-spacing: 0.08em;
            margin-bottom: 5px; }
            @media (max-width: 1023px) {
              #main-menu .flex-wrapper .right-actions .action-content--title {
                margin: 0; } }
          #main-menu .flex-wrapper .right-actions .action-content--subtitle {
            color: white;
            font-size: 10px;
            font-weight: bold;
            text-transform: uppercase;
            letter-spacing: 0.08em;
            transition-property: color;
            transition-duration: 0.5s;
            transition-timing-function: cubic-bezier(0.5, 0, 0, 1); }
            @media (max-width: 1023px) {
              #main-menu .flex-wrapper .right-actions .action-content--subtitle {
                display: none; } }
          #main-menu .flex-wrapper .right-actions .action-content--phone {
            color: white;
            font-size: 16px;
            font-weight: normal;
            text-transform: uppercase;
            letter-spacing: 0.08em;
            transition-property: color;
            transition-duration: 0.5s;
            transition-timing-function: cubic-bezier(0.5, 0, 0, 1); }
            @media (max-width: 1023px) {
              #main-menu .flex-wrapper .right-actions .action-content--phone {
                display: none; } }
            #main-menu .flex-wrapper .right-actions .action-content--phone small {
              font-size: 10px;
              font-weight: bold; }
          #main-menu .flex-wrapper .right-actions .action:hover .action-icon svg {
            transform: translate(-50%, -50%) scale(1.2); }
          #main-menu .flex-wrapper .right-actions .action:hover .action-content > * {
            color: #93b1d3; }
      #main-menu .flex-wrapper .right-location {
        opacity: 0;
        transition-property: opacity;
        transition-duration: 0.7s;
        transition-timing-function: cubic-bezier(0.5, 0, 0, 1); }
        @media (max-width: 1023px) {
          #main-menu .flex-wrapper .right-location {
            display: none; } }
        #main-menu .flex-wrapper .right-location--title {
          color: white;
          font-size: 10px;
          font-weight: 700;
          letter-spacing: 0.24em;
          text-transform: uppercase;
          margin-bottom: 25px; }
          @media (max-height: 590px) {
            #main-menu .flex-wrapper .right-location--title {
              margin-bottom: 15px; } }
        #main-menu .flex-wrapper .right-location--description {
          width: 185px; }
          #main-menu .flex-wrapper .right-location--description p {
            color: #93b1d3;
            font-size: 12px;
            font-weight: 700;
            line-height: 1.6;
            letter-spacing: 0.04em; }
        #main-menu .flex-wrapper .right-location--cta {
          margin-top: 15px; }
          @media (max-height: 590px) {
            #main-menu .flex-wrapper .right-location--cta {
              margin-top: 10px; } }
          #main-menu .flex-wrapper .right-location--cta a {
            border-bottom: 1px solid white;
            padding-bottom: 5px;
            transition-property: border-color;
            transition-duration: 0.7s;
            transition-timing-function: cubic-bezier(0.5, 0, 0, 1); }
            #main-menu .flex-wrapper .right-location--cta a span {
              margin-right: 10px;
              color: white;
              font-size: 10px;
              font-weight: 700;
              letter-spacing: 0.08em;
              text-transform: uppercase;
              transition-property: color;
              transition-duration: 0.7s;
              transition-timing-function: cubic-bezier(0.5, 0, 0, 1); }
            #main-menu .flex-wrapper .right-location--cta a svg path {
              transition-property: fill;
              transition-duration: 0.7s;
              transition-timing-function: cubic-bezier(0.5, 0, 0, 1); }
            #main-menu .flex-wrapper .right-location--cta a:hover {
              border-color: #93b1d3; }
              #main-menu .flex-wrapper .right-location--cta a:hover span {
                color: #93b1d3; }
              #main-menu .flex-wrapper .right-location--cta a:hover svg path {
                fill: #93b1d3; }
      #main-menu .flex-wrapper .right-social {
        opacity: 0;
        transition-property: opacity;
        transition-duration: 0.7s;
        transition-timing-function: cubic-bezier(0.5, 0, 0, 1); }
        @media (max-width: 1023px) {
          #main-menu .flex-wrapper .right-social {
            margin-top: 35px; } }
        @media (max-width: 355px) {
          #main-menu .flex-wrapper .right-social {
            margin-top: 25px; } }
        #main-menu .flex-wrapper .right-social ul {
          display: flex;
          align-items: center; }
          #main-menu .flex-wrapper .right-social ul li:not(:last-child) {
            margin-right: 30px; }
          #main-menu .flex-wrapper .right-social ul li a {
            display: inline-block; }
            #main-menu .flex-wrapper .right-social ul li a svg path {
              transition-property: fill;
              transition-duration: 0.7s;
              transition-timing-function: cubic-bezier(0.5, 0, 0, 1); }
            #main-menu .flex-wrapper .right-social ul li a:hover svg path {
              fill: #93b1d3; }
  #main-menu.open {
    opacity: 1;
    pointer-events: initial; }
    #main-menu.open .background::after {
      width: 1px;
      transform: translateY(-50%) scaleY(1);
      transition-delay: 0.2s; }
    #main-menu.open .flex-wrapper .left-navigation ul:nth-child(1) li {
      opacity: 1;
      transform: translateX(0); }
      #main-menu.open .flex-wrapper .left-navigation ul:nth-child(1) li:nth-child(0) {
        transition-delay: 0.3s; }
      #main-menu.open .flex-wrapper .left-navigation ul:nth-child(1) li:nth-child(1) {
        transition-delay: 0.4s; }
      #main-menu.open .flex-wrapper .left-navigation ul:nth-child(1) li:nth-child(2) {
        transition-delay: 0.5s; }
      #main-menu.open .flex-wrapper .left-navigation ul:nth-child(1) li:nth-child(3) {
        transition-delay: 0.6s; }
      #main-menu.open .flex-wrapper .left-navigation ul:nth-child(1) li:nth-child(4) {
        transition-delay: 0.7s; }
    #main-menu.open .flex-wrapper .left-navigation ul:nth-child(2) {
      opacity: 1;
      transition-delay: 0.5s; }
      #main-menu.open .flex-wrapper .left-navigation ul:nth-child(2) li {
        opacity: 1;
        transform: translateX(0); }
        #main-menu.open .flex-wrapper .left-navigation ul:nth-child(2) li:nth-child(0) {
          transition-delay: 0.5s; }
        #main-menu.open .flex-wrapper .left-navigation ul:nth-child(2) li:nth-child(1) {
          transition-delay: 0.6s; }
        #main-menu.open .flex-wrapper .left-navigation ul:nth-child(2) li:nth-child(2) {
          transition-delay: 0.7s; }
        #main-menu.open .flex-wrapper .left-navigation ul:nth-child(2) li:nth-child(3) {
          transition-delay: 0.8s; }
        #main-menu.open .flex-wrapper .left-navigation ul:nth-child(2) li:nth-child(4) {
          transition-delay: 0.9s; }
    #main-menu.open .flex-wrapper .left-actions .action {
      opacity: 1;
      transform: translateX(0); }
      #main-menu.open .flex-wrapper .left-actions .action:nth-child(0) {
        transition-delay: 0.5s; }
      #main-menu.open .flex-wrapper .left-actions .action:nth-child(1) {
        transition-delay: 0.6s; }
      #main-menu.open .flex-wrapper .left-actions .action:nth-child(2) {
        transition-delay: 0.7s; }
      #main-menu.open .flex-wrapper .left-actions .action:nth-child(3) {
        transition-delay: 0.8s; }
      #main-menu.open .flex-wrapper .left-actions .action:nth-child(4) {
        transition-delay: 0.9s; }
    #main-menu.open .flex-wrapper .left-bottom {
      opacity: 1;
      transition-delay: 0.8s; }
    #main-menu.open .flex-wrapper .right-actions .action {
      opacity: 1;
      transform: translateY(0); }
      #main-menu.open .flex-wrapper .right-actions .action:nth-child(0) {
        transition-delay: 0.8s; }
      #main-menu.open .flex-wrapper .right-actions .action:nth-child(1) {
        transition-delay: 0.9s; }
      #main-menu.open .flex-wrapper .right-actions .action:nth-child(2) {
        transition-delay: 1s; }
      #main-menu.open .flex-wrapper .right-actions .action:nth-child(3) {
        transition-delay: 1.1s; }
      #main-menu.open .flex-wrapper .right-actions .action:nth-child(4) {
        transition-delay: 1.2s; }
    #main-menu.open .flex-wrapper .right-location {
      opacity: 1;
      transition-delay: 1.1s; }
    #main-menu.open .flex-wrapper .right-social {
      opacity: 1;
      transition-delay: 1.2s; }

.modal-call {
  background-color: black;
  position: fixed;
  opacity: 0;
  pointer-events: none;
  transition-property: opacity;
  transition-duration: 0.7s;
  transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
  z-index: 80; }
  @media (max-width: 1023px) {
    .modal-call {
      overflow: auto; } }
  .modal-call.open {
    opacity: 1;
    pointer-events: initial; }
  .modal-call .background {
    background-color: black;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: calc(50% - 50px);
    z-index: 1; }
    @media (max-width: 1023px) {
      .modal-call .background {
        height: 380px; } }
    .modal-call .background img {
      opacity: 0.3; }
  .modal-call .container {
    position: relative;
    z-index: 2; }
  .modal-call .logo {
    position: absolute;
    top: 40px;
    left: 25px;
    z-index: 3; }
  .modal-call .close {
    position: absolute;
    top: 40px;
    right: 25px;
    z-index: 5; }
  .modal-call .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; }
    @media (max-width: 1023px) {
      .modal-call .wrapper {
        height: auto;
        min-height: 100vh;
        padding: 135px 0 60px; } }
    .modal-call .wrapper-header {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      width: 765px; }
      @media (max-width: 1023px) {
        .modal-call .wrapper-header {
          flex-direction: column;
          width: 100%; } }
      .modal-call .wrapper-header--title {
        color: white;
        font-family: "Canela";
        font-size: 56px;
        font-weight: 300;
        width: 285px; }
        @media (max-width: 1023px) {
          .modal-call .wrapper-header--title {
            font-size: 48px; } }
        @media (max-width: 355px) {
          .modal-call .wrapper-header--title {
            font-size: 40px; } }
      .modal-call .wrapper-header--description {
        width: 275px;
        padding-top: 15px; }
        @media (max-width: 1023px) {
          .modal-call .wrapper-header--description {
            width: 100%;
            padding: 0;
            margin-top: 24px; } }
        .modal-call .wrapper-header--description p {
          color: #93b1d3;
          font-size: 14px;
          font-weight: normal;
          line-height: 1.8; }
          @media (max-width: 1023px) {
            .modal-call .wrapper-header--description p {
              color: white;
              font-size: 16px;
              font-weight: normal; } }
    .modal-call .wrapper-form {
      width: 765px;
      margin-top: 120px; }
      @media (max-width: 1023px) {
        .modal-call .wrapper-form {
          width: 100%; } }
      .modal-call .wrapper-form .form-flex {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 24px;
        grid-row-gap: 32px; }
        @media (max-width: 1023px) {
          .modal-call .wrapper-form .form-flex {
            grid-template-columns: repeat(1, 1fr); } }
        .modal-call .wrapper-form .form-flex:not(:last-child) {
          margin-bottom: 32px; }
        .modal-call .wrapper-form .form-flex .field-group {
          position: relative;
          width: 100%; }
          .modal-call .wrapper-form .form-flex .field-group .label {
            color: white;
            font-size: 10px;
            font-weight: bold;
            letter-spacing: 0.08em;
            text-transform: uppercase;
            margin-bottom: 10px; }
          .modal-call .wrapper-form .form-flex .field-group svg {
            position: absolute;
            bottom: 14px;
            left: 16px;
            pointer-events: none;
            z-index: 1; }
          .modal-call .wrapper-form .form-flex .field-group .field {
            background-color: #0f0f0f;
            color: #93b1d3;
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 0.02em;
            width: 100%;
            display: block;
            height: 40px;
            padding: 0 16px;
            border: none;
            border-bottom: 1px solid #93b1d3;
            transition-property: background-color;
            transition-duration: 0.7s;
            transition-timing-function: cubic-bezier(0.5, 0, 0, 1); }
            .modal-call .wrapper-form .form-flex .field-group .field::placeholder {
              color: #93b1d3; }
          .modal-call .wrapper-form .form-flex .field-group.icon .field {
            padding-left: 50px; }
          .modal-call .wrapper-form .form-flex .field-group.invalid .field {
            background-color: rgba(255, 0, 0, 0.2); }
      .modal-call .wrapper-form .form-submit {
        width: 370px;
        margin: 48px auto 0; }
        @media (max-width: 1023px) {
          .modal-call .wrapper-form .form-submit {
            max-width: 100%; } }
        .modal-call .wrapper-form .form-submit button {
          width: 100%;
          height: 48px;
          border-radius: 50px;
          overflow: hidden; }
          .modal-call .wrapper-form .form-submit button span {
            color: black;
            font-size: 10px;
            font-weight: bold;
            letter-spacing: 0.08em;
            text-transform: uppercase; }
