#main-header {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  pointer-events: none;
  z-index: 50;

  .flex-wrapper {
    padding: 40px 25px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    transition-property: padding;
    transition-duration: time(default);
    transition-timing-function: ease(inout);

    .logo {
      a {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 112px;
        height: 52px;
        transition-property: background-color, width, height;
        transition-duration: time(default);
        transition-timing-function: ease(inout);
      }
    }

    .menu {
      button {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        height: 28px;
        transition-property: background-color, width, height;
        transition-duration: time(default);
        transition-timing-function: ease(inout);
      }
    }

    > * {
      pointer-events: initial;
    }
  }

  &.sticky .flex-wrapper {
    padding: 0;

    .logo a {
      background-color: black;
      width: 155px;
      height: 80px;
    }

    .menu button {
      background-color: black;
      width: 80px;
      height: 80px;
    }
  }
}
