#main-actions {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 290px;
  pointer-events: none;
  z-index: 50;

  @media (max-width: 1023px) {
    width: 100%;
  }

  .toggle {
    position: relative;
    width: 100%;
    height: 60px;
    pointer-events: initial;
    z-index: 1;

    button {
      @extend %gradient-button;

      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      width: 100%;
      height: 100%;
      padding: 1px 0 0;

      span {
        color: black;
        font-size: 10px;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 0.08em;
        display: inline-block;
        margin-right: 16px;
      }

      svg path {
        fill: black;
      }
    }
  }

  .actions {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    bottom: 60px;
    width: 100%;
    padding: 32px 48px;
    z-index: 2;

    &::before {
      @extend %cover;

      content: '';
      background-color: black;
      transform-origin: 50% 100%;
      transform: scaleY(0);
      transition-property: transform;
      transition-duration: time(fast);
      transition-timing-function: ease(inout);
      transition-delay: 0.4s;
      z-index: 1;
    }

    &::after {
      content: '';
      background-color: black;
      position: absolute;
      bottom: -12px;
      left: 50%;
      transform: translate(-50%, -13px);
      width: 26px;
      height: 13px;
      clip-path: polygon(0 0, 100% 0, 50% 100%);
      opacity: 0;
      transition-property: opacity, transform;
      transition-duration: time(fast);
      transition-timing-function: ease(inout);
      transition-delay: 0.4s;
      z-index: 3;
    }

    .action {
      position: relative;
      display: flex;
      align-items: center;
      opacity: 0;
      transform: translateY(10px);
      transition-property: opacity, transform;
      transition-duration: time(fast);
      transition-timing-function: ease(inout);
      z-index: 2;

      @for $i from 0 to 5 {
        &:nth-child(#{$i}) {
          transition-delay: 0.1s * $i;
        }
      }

      &:not(:last-child) {
        margin-bottom: 24px;
      }

      &-link {
        @extend %cover;

        z-index: 5;
      }

      &-icon {
        position: relative;
        width: 48px;
        height: 48px;
        flex-shrink: 0;
        margin-right: 8px;

        picture,
        img {
          display: block;
        }

        svg {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          transition-property: transform;
          transition-duration: time(fast);
          transition-timing-function: ease(inout);
          z-index: 2;
        }
      }

      &-content {
        &--title {
          color: #93b1d3;
          font-size: 10px;
          font-weight: bold;
          text-transform: uppercase;
          letter-spacing: 0.08em;
          margin-bottom: 5px;
        }

        &--subtitle {
          color: white;
          font-size: 10px;
          font-weight: bold;
          text-transform: uppercase;
          letter-spacing: 0.08em;
          transition-property: color;
          transition-duration: time(fast);
          transition-timing-function: ease(inout);
        }

        &--phone {
          color: white;
          font-size: 16px;
          font-weight: normal;
          text-transform: uppercase;
          letter-spacing: 0.08em;
          transition-property: color;
          transition-duration: time(fast);
          transition-timing-function: ease(inout);

          small {
            font-size: 10px;
            font-weight: bold;
          }
        }
      }

      &:hover {
        .action-icon svg {
          transform: translate(-50%, -50%) scale(1.2);
        }

        .action-content {
          > * {
            color: #93b1d3;
          }
        }
      }
    }
  }

  &.open {
    .actions {
      pointer-events: initial;

      &::before {
        transform: scaleY(1);
        transition-delay: 0s;
      }

      &::after {
        opacity: 1;
        transform: translate(-50%, 0);
        transition-delay: 0s;
      }

      .action {
        opacity: 1;
        transform: translateY(0);

        &:nth-child(1) {
          transition-delay: 0.4s;
        }

        &:nth-child(2) {
          transition-delay: 0.3s;
        }

        &:nth-child(3) {
          transition-delay: 0.2s;
        }

        &:nth-child(4) {
          transition-delay: 0.1s;
        }
      }
    }
  }
}
