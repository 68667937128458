@font-face {
  font-family: 'Canela';
  src: url('../../assets/fonts/Canela-Regular.woff2') format('woff2'),
    url('../../assets/fonts/Canela-Regular.woff') format('woff'),
    url('../../assets/fonts/Canela-Regular.svg#Canela-Regular') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Product Sans';
  src: url('../../assets/fonts/ProductSans-Bold.woff2') format('woff2'),
    url('../../assets/fonts/ProductSans-Bold.woff') format('woff'),
    url('../../assets/fonts/ProductSans-Bold.svg#ProductSans-Bold')
      format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Product Sans';
  src: url('../../assets/fonts/ProductSans-Regular.woff2') format('woff2'),
    url('../../assets/fonts/ProductSans-Regular.woff') format('woff'),
    url('../../assets/fonts/ProductSans-Regular.svg#ProductSans-Regular')
      format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
